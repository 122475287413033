













import { defineComponent, ref, computed, watch, onMounted, toRefs, } from "@vue/composition-api";
import { AccountsClient, OrganizationMemberRegistrationModel } from "@/api/OtiumAppApi";
import AuthenticationBasePage from "@/components/AuthenticationBasePage.vue";
import CreateAccountForm from "@/components/authentication/CreateAccountForm.vue";

export default defineComponent({
  name: "AcceptInvite",
  components: { AuthenticationBasePage, CreateAccountForm },
  setup(props, { root }) {
    const accountsClient = new AccountsClient();

    const invitation = ref<OrganizationMemberRegistrationModel | null>(null);

    async function loadInvitation() {
      const invitationId = root.$route.query["id"];
      if (invitationId == undefined) {
        root.$router.push({ "name": "Login" });
        return;
      }

      invitation.value = await accountsClient.getOrganizationMemberInvitation(invitationId as string);

      if (invitation.value.isRegistered) {
        root.$router.push({ "name": "Dashboard" });
        return;
      }
    }

    onMounted(loadInvitation);

    return {
      invitation,
    }
  }
});
